<template>
  <div>
    <v-dialog v-model="viewMembersProfile" persistent width="280px">
      <v-toolbar dense class="elevation-0 gradient-bg white--text">
        New Profile
        <v-spacer />
        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card height="220px">
        <v-card-text align="center" justify="center" class="pt-10">
          <div style="border: 1px solid gray; width: fit-content">
            <v-img
              height="145"
              width="140"
              :src="approvaldta.payload.s3_url"
            ></v-img>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
export default {
  props: {
    viewMembersProfile: Boolean,
    approvaldta: Object,
  },
  data: () => ({}),

  methods: {
    close_dialog() {
      this.$emit("clicked", 0);
    },
  },
};
</script>
  
  <style>
</style>