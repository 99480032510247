var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-4",attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('v-text-field',{staticClass:"mt-3",staticStyle:{"max-width":"220px"},attrs:{"label":"Search","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"overflow-y-auto overflow mt-n5",attrs:{"height":_vm.height,"dense":"","search":_vm.search,"headers":_vm.tabledata,"items":_vm.exporttable,"items-per-page":10,"loading":_vm.loadingreport,"fixed-header":_vm.fixed,"loading-text":"Loading... Please wait","footer-props":{
          'items-per-page-options': [10, 15, 20, 25],
        }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No Profile Picture Applied. ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true},{key:"item.images",fn:function(ref){
        var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.view_profile(item)}}},[_c('v-avatar',{staticClass:"ma-1",attrs:{"size":"36"}},[_c('v-img',{attrs:{"src":item.payload.s3_url}})],1)],1)]}},{key:"item.created_on",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"overflow",domProps:{"textContent":_vm._s(_vm.get_date(item.created_on))}})]}},{key:"item.approved_on",fn:function(ref){
        var item = ref.item;
return [(item.approved_on == null)?_c('span',{staticClass:"ml-5"},[_vm._v("-")]):_c('span',{domProps:{"textContent":_vm._s(_vm.get_date(item.approved_on))}})]}},{key:"item.approval_status",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.approval_status == 'APPROVED'),expression:"item.approval_status == 'APPROVED'"}],staticStyle:{"color":"green"},attrs:{"small":"","depressed":"","medium":""}},on),[_vm._v(" mdi-account-check ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("APPROVED")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.approval_status == 'PENDING'),expression:"item.approval_status == 'PENDING'"}],staticStyle:{"color":"orange"},attrs:{"small":"","depressed":"","mediumapproved_on":""},on:{"click":function($event){return _vm.Approve(item)}}},on),[_vm._v(" mdi-account-alert ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("PENDING ")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.approval_status == 'REJECTED'),expression:"item.approval_status == 'REJECTED'"}],staticStyle:{"color":"red"},attrs:{"small":"","depressed":"","medium":""}},on),[_vm._v(" mdi-account-cancel ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("REJECTED ")])])]}}],null,true)})],1)],1),(_vm.componentcheck == 1)?_c('div',[_c('ApproveProfil',{attrs:{"profileApproveReject":_vm.profileApproveReject,"approvaldta":_vm.approvaldta},on:{"errorMsg":_vm.errorPop,"successMsg":_vm.sucessPop,"clicked":function($event){_vm.profileApproveReject = false}}})],1):_vm._e(),(_vm.componentcheck == 2)?_c('div',[_c('NewProfileview',{attrs:{"viewMembersProfile":_vm.viewMembersProfile,"approvaldta":_vm.approvaldta},on:{"clicked":function($event){_vm.viewMembersProfile = false}}})],1):_vm._e(),_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c('Overlay',{attrs:{"overlay":_vm.overlay}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }