<template>
  <div>
    <v-card flat class="mt-4">
      <v-toolbar dense elevation="0">
        <v-text-field
          v-model="search"
          label="Search"
          style="max-width: 220px"
          dense
          append-icon="mdi-magnify"
          class="mt-3"
        ></v-text-field>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :height="height"
          class="overflow-y-auto overflow mt-n5"
          dense
          :search="search"
          :headers="tabledata"
          :items="exporttable"
          :items-per-page="10"
          :loading="loadingreport"
          :fixed-header="fixed"
          loading-text="Loading... Please wait"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
        >
          <template v-slot:no-data>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No Profile Picture Applied.
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.images`]="{ item }">
            <a @click="view_profile(item)">
              <v-avatar size="36" class="ma-1">
                <v-img :src="item.payload.s3_url" />
              </v-avatar>
            </a>
          </template>
          <template v-slot:[`item.created_on`]="{ item }">
            <span class="overflow" v-text="get_date(item.created_on)"></span>
          </template>
          <template v-slot:[`item.approved_on`]="{ item }">
            <span v-if="item.approved_on == null" class="ml-5">-</span>
            <span v-else v-text="get_date(item.approved_on)"></span>
          </template>

          <template v-slot:[`item.approval_status`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  depressed
                  style="color: green"
                  medium
                  v-show="item.approval_status == 'APPROVED'"
                >
                  mdi-account-check
                </v-icon>
              </template>
              <span class="white--text">APPROVED</span>
            </v-tooltip>

            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  depressed
                  style="color: orange"
                  @click="Approve(item)"
                  v-show="item.approval_status == 'PENDING'"
                  mediumapproved_on
                >
                  mdi-account-alert
                </v-icon>
              </template>
              <span class="white--text">PENDING </span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  depressed
                  style="color: red"
                  medium
                  v-show="item.approval_status == 'REJECTED'"
                >
                  mdi-account-cancel
                </v-icon>
              </template>
              <span class="white--text">REJECTED </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div v-if="componentcheck == 1">
      <ApproveProfil
        v-on:errorMsg="errorPop"
        v-on:successMsg="sucessPop"
        :profileApproveReject="profileApproveReject"
        :approvaldta="approvaldta"
        @clicked="profileApproveReject = false"
      />
    </div>
    <div v-if="componentcheck == 2">
      <NewProfileview
        :viewMembersProfile="viewMembersProfile"
        :approvaldta="approvaldta"
        @clicked="viewMembersProfile = false"
      />
    </div>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <Overlay :overlay="overlay" />
  </div>
</template>

<script>
import NewProfileview from "@/components/Dialogs/NewProfileview.vue";
import ApproveProfil from "../../components/Dialogs/ApproveProfil.vue";
import SnackBar from "@/components/SnackBar.vue";
import Overlay from "@/components/Overlay.vue";
import { API, graphqlOperation } from "aws-amplify";
import { list_face_approvals } from "@/graphql/queries.js";
export default {
  components: {
    SnackBar,
    ApproveProfil,
    NewProfileview,
    Overlay,
  },
  data() {
    return {
      height: 0,
      viewMembersProfile: false,
      search: "",
      tabledata: [
        {
          text: "New  Profile ",
          fixed: true,
          value: "images",
          sortable: false,
        },
        { text: "Member Name", fixed: true, value: "user_name" },
        { text: "Requested On", fixed: true, value: "created_on" },
        { text: "Approved On", fixed: true, value: "approved_on" },

        {
          text: "Status",
          fixed: true,
          value: "approval_status",
          sortable: false,
        },
      ],
      exporttable: [{ leave_from_date: "M01", user_name: "Darshan" }],
      fixed: true,
      loadingreport: false,
      profileApproveReject: false,
      approvaldta: {},
      componentcheck: 0,
      SnackBarComponent: {},
      overlay: false,
    };
  },
  created() {
    this.height = window.innerHeight - 200;
    this.get_leave();
  },
  methods: {
    view_profile(item) {
      this.approvaldta = item;
      this.componentcheck = 2;
      this.viewMembersProfile = true;
    },
    get_date(date) {
      const options = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      let a = new Date(date * 1000).toLocaleString("en-US", options);

      // Splitting the date into components
      const [datePart, timePart] = a.split(", ");
      const [month, day, year] = datePart.split("/");

      // Reformatting to "dd/mm/yyyy HH:MM:SS" format
      return `${day}/${month}/${year} ${timePart}`;
    },
    Approve(item) {
      this.approvaldta = item;
      this.componentcheck = 1;
      this.profileApproveReject = true;
    },
    errorPop(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        Top: true,
        SnackbarText: val,
      };
    },
    sucessPop(val) {
      this.profileApproveReject = false;
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: val,
      };
      this.exporttable = [];
      this.get_leave();
    },
    async get_leave() {
      this.overlay = true;
      this.loadingreport = true;
      try {
        let result = await API.graphql(
          graphqlOperation(list_face_approvals, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              user_email_id:
                this.$store.getters.get_org_user_details[0].user_email_id,
            },
          })
        );
        var respnse = result.data.list_face_approvals.items;
        this.exporttable = respnse;

        this.loadingreport = false;
        this.overlay = false;
      } catch (error) {
        console.log(error);
        this.loadingreport = false;
        this.overlay = false;
        this.isLoading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
  },
};
</script>

<style>
</style>