<template>
  <div>
    <v-dialog v-model="profileApproveReject" persistent width="390px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0">
          <v-toolbar-title class="white--text">Profile Approval</v-toolbar-title
          ><v-spacer />
          <v-icon @click="close_dialog()" class="white--text">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
        >
          <div class="mt-5">
            Would you like to Approve / Reject Profile Picture for <br />
            {{ approvaldta.user_name }} ?
          </div>
          <!-- <div class="d-flex justify-center">
            <img contain width="120px" height="110px" :src="url" />
          </div> -->
          <v-form ref="form">
            <v-textarea
              v-model="leavereason"
              height="100px"
              dense
              label="Comments"
              class="mt-5"
              :rules="[
                (v) => !!v.trim() || 'Required',
                (v) =>
                  v.length <= 100 || 'Comments  must be under 100 characters',
              ]"
              outlined
            />
          </v-form>
        </v-card-text>
        <div class="d-flex justify-center mb-3">
          <v-card-actions class="mt-n5">
            <v-btn
              color="primary"
              class="white--text"
              @click="reject_datacorrection()"
              small
              >Reject</v-btn
            >
            <v-btn
              color="primary"
              class="white--text"
              @click="approvedata()"
              small
              >Approve</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
    
    <script>
import { API, graphqlOperation } from "aws-amplify";
import { approve_all_the_flows } from "@/graphql/mutations.js";
export default {
  props: {
    profileApproveReject: Boolean,
    approvaldta: Object,
  },
  data: () => ({
    leavereason: "",
    url: "",
  }),
  watch: {
    profileApproveReject: {
      handler() {
        // console.log(this.approvaldta.approval_id);
        this.url = this.approvaldta.payload.s3_url;
      },
      immediate: true,
    },
  },
  methods: {
    async reject_datacorrection() {
      if (this.$refs.form.validate()) {
        try {
          let result = await API.graphql(
            graphqlOperation(approve_all_the_flows, {
              input: {
                approval_id: this.approvaldta.approval_id,
                approval_status: "REJECTED",
                user_email_id:
                  this.$store.getters.get_org_user_details[0].user_email_id,
                approvals_comment: this.leavereason,
                organisation_id:
                  this.$store.getters.get_org_details[0].organisation_id,
              },
            })
          );
          // console.log(result.data.approve_all_the_flows);
          var respnse = result.data.approve_all_the_flows;
          if (respnse.Status == "ERROR") {
            this.$emit("errorMsg", respnse.Message);
          } else {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.$emit("successMsg", respnse.Message);
          }
        } catch (error) {
          this.$emit("errorMsg", error.errors[0].message);
          console.log(error);
        }
      }
    },
    async approvedata() {
      if (this.$refs.form.validate()) {
        try {
          let result = await API.graphql(
            graphqlOperation(approve_all_the_flows, {
              input: {
                approval_id: this.approvaldta.approval_id,
                approval_status: "APPROVED",
                user_email_id:
                  this.$store.getters.get_org_user_details[0].user_email_id,
                approvals_comment: this.leavereason,
                organisation_id:
                  this.$store.getters.get_org_details[0].organisation_id,
              },
            })
          );

          var respnse = result.data.approve_all_the_flows;
          if (respnse.Status == "ERROR") {
            this.$emit("errorMsg", respnse.Message);
          } else {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.$emit("successMsg", respnse.Message);
          }
        } catch (error) {
          this.$emit("errorMsg", error.errors[0].message);
          console.log(error);
        }
      }
    },
    close_dialog() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$emit("clicked", 0);
    },
  },
};
</script>
    
<style>
</style>